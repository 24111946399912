import { Component, OnInit, ComponentFactoryResolver, ViewChild, ViewChildren, QueryList, ViewContainerRef, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { HasSubscriptions } from '@shared/utilities';
import { HttpClient } from '@angular/common/http';
import { Section, Quiz } from '@shared/models';
import { TaskDirective } from 'src/app/tasks/task.directive';
import { TaskComponent } from 'src/app/tasks/task';
import { Tasks } from 'src/app/tasks/task.constants';
import * as _ from 'lodash';
import { MatSnackBar } from '@angular/material';
import { AuthService, ProgressService } from '@shared/services';
import * as firebase from 'firebase';
import { DomSanitizer } from '@angular/platform-browser';
import { withLatestFrom } from 'rxjs-compat/operator/withLatestFrom';
import { take } from 'rxjs/operators';
@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.css']
})
export class QuizComponent extends HasSubscriptions implements OnInit {

  public currentSlide = 0;
  public currentSection: Section;
  public currentLesson: string;
  public currentSectionId: string;
  public isLoading = true;
  public slideDone: boolean[];
  public progressData = {};
  public isAnswered = false;
  public _ = _;

  @ViewChildren(TaskDirective) taskContainers: QueryList<TaskDirective>;

  constructor(public auth:AuthService, private changeDetectorRef: ChangeDetectorRef, private sanitizer: DomSanitizer, private snackBar: MatSnackBar, public url: ActivatedRoute, public files: HttpClient, private componentFactoryResolver: ComponentFactoryResolver, private progress: ProgressService ) {
    super();
  }

  ngAfterViewInit() {
    this.safeSubscribe<Params>(
      this.url.params,
      (params: Params) => {
        this.isLoading = true;
        this.loadSection(params['lesson'], params['section']);
        this.currentLesson = params['lesson'];
        this.currentSectionId = params['section'];
        this.currentSlide = 0;
      }
    );
  }

  nextSl() {
    this.isAnswered = false;
    this.currentSlide++;
  }

  private loadSection(lesson: string, section: string) {
    this.safeSubscribe(
      this.files.get('assets/data/sections.json'),
      (fileContent) => {
        if (fileContent[lesson]) {
          this.slideDone = new Array(fileContent[lesson][section].quizes.length).fill(false);
          this.currentSection = fileContent[lesson][section];
        } else {
          this.currentSection = null;
        }
        this.isLoading = false;
        setTimeout(() => this.loadAllTasks(this.currentSection.quizes));
      }
    );

  }

  private loadAllTasks(quizes: Quiz[]) {
    for (const quizIndex in quizes) {
      this.loadTask(this.taskContainers.toArray()[quizIndex].viewContainerRef, quizes[quizIndex], parseInt(quizIndex));
    }
  }

  preventDragOnMatching(index, e) {
    if (index < this.currentSection.quizes.length && this.currentSection.quizes[index].type == 'matching') {
      e.stopPropagation();
    }
  }

  private loadTask(viewContainerRef: ViewContainerRef, task: Quiz, index: number) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(Tasks[task.type]);
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent(componentFactory);
    if(task.data.question) {
      task.data.question = this.sanitizer.bypassSecurityTrustHtml(task.data.question);
    }
    (<TaskComponent>componentRef.instance).data = task.data;
    this.progress.updated.subscribe(() => {
      (<TaskComponent>componentRef.instance).data = task.data;
      this.progressData = this.progress.getProgress();
      if(this.progressData ) {
        (<TaskComponent>componentRef.instance).progress = this.progressData[this.currentLesson].sections[this.currentSectionId].quizResults[index];
        if((<TaskComponent>componentRef.instance).progress && (<TaskComponent>componentRef.instance).progress.answer) {
          this.slideDone[index] = true;
        }
        if (!componentRef.changeDetectorRef['destroyed']) {
          componentRef.changeDetectorRef.detectChanges();
        }
      }
    });
    this.safeSubscribe(
      (<TaskComponent>componentRef.instance).taskSubmitted,
      async (data: {points: number, answer: any}) => {
        console.log("HERE");
        this.isAnswered = true;
        console.log(task.data, data)
        if (this.currentSlide < this.currentSection.quizes.length) {
          let newProgress = this.progress.getProgress();
          newProgress[this.currentLesson].sections[this.currentSectionId].quizResults[index] = {
            maxPoints: task.data.points,
            points: data.points,
            answer: data.answer
          };
          if (this.currentSlide == this.currentSection.quizes.length - 1) {
            newProgress = _.merge(
              newProgress,
            {
              [this.currentLesson]: {
                finished: !this.currentSection.next || this.currentLesson != this.currentSection.next[0],
                sections: {
                  [this.currentSectionId]: {
                    finished: true
                  }
                }
              }
            });
            if(this.currentSection.next) {
              newProgress = _.merge(newProgress,
                {
                  [this.currentSection.next[0]]: {
                    unlocked: true,
                    sections: {
                      [this.currentSection.next[1]]: {
                        unlocked: true
                      }
                    }
                  }
                }
              )
            }
          }
          this.progress.updateProgress(this.auth.userData.pipe(take(1)).toPromise(), newProgress);
        }
      }
    );
  }

  getInflection(points: number) {
    console.log(points);
    return points === 1 ? '' : (points > 1 && points < 5 ? 'y' : 'ů');
  }

  ngOnInit() {
  }

  getQuizIcon(quiz) {
    return quiz.points == 0 ? 'close' : 'done';
  }

  calculatePoints(lesson, section, quiz): {max: number, current: number} {
    const quizProgress = this.progressData[lesson].sections[section].quizResults[quiz];
    return { max: quizProgress.maxPoints, current: quizProgress.points };
  }

  pointsShow(points: {max: number, current: number}) {
    if (points.max == 0) {
      return '';
    }
    return '(Body: ' + points.current.toString() + '/' + points.max.toString() + ')';
  }

}
