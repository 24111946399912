import { Component, OnInit } from '@angular/core';
import { AuthService, ProgressService } from './shared';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  constructor(private progress: ProgressService,
    private auth: AuthService){
  }

  public ngOnInit(): void {
    this.auth.userData.subscribe((authState)=> {
      if(authState && authState.uid) {
        this.progress.initProgress( authState.uid);
      }
    });
  }
}
