import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import * as firebase from 'firebase';
import { Observable } from 'rxjs';
import { ProgressService } from './progress.service';

@Injectable()
export class AuthService {

  userData: Observable<firebase.User>;

  constructor(
    private router: Router,
    private progress: ProgressService,
    private angularFireAuth: AngularFireAuth) { 
      this.userData = this.angularFireAuth.authState;
    }

      /* Sign up */
  register(email: string, password: string) {
    this.angularFireAuth.auth
    .createUserWithEmailAndPassword(email, password)
    .then(res => {
      console.log('You are Successfully signed up!', res);
      this.progress.initProgress(res.user.uid);
      this.router.navigate(['/']);
    })
    .catch(error => {
      console.log('Something is wrong:', error.message);
    });
  }
 
  /* Sign in */
  login(email: string, password: string) {
    this.angularFireAuth.auth
    .signInWithEmailAndPassword(email, password)
    .then(res => {
      console.log('You are Successfully logged in!');
      this.progress.initProgress(res.user.uid);
      this.router.navigate(['/']);
    })
    .catch(err => {
      console.log('Something is wrong:',err.message);
    });
  }
 
  /* Sign out */
  public logout(): void {
    this.angularFireAuth
    .auth
    .signOut();
    this.router.navigate(['/']);
  }
}