import { Component } from '@angular/core';

import { AuthService, AlertService } from '../../services';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  public logo: string = 'assets/img/logo.png';

  constructor(
    public authService: AuthService,
    private alertService: AlertService
    ) {
  }

  public onLogout(): void {
    this.alertService.showToaster('Odhlášení proběhlo úspěšně.');
    return this.authService.logout();
  }
}
